body {
    .start-fen {
        margin: 5px;
        height: 38px;
        border-radius: 5px;
        width: 485px;
    }
}
.position-layout {
    padding: 0px;
    display: flex;
    margin: 5px auto;
    width: 100%;
}

.position-select {
    width: 500px;
    text-align: center;
    height: 38px;
    padding: 0 10px;
    border-radius: 5px;
    background-position: right 0.75rem center;
}

.position-select:focus {
    outline: none;
    box-shadow: 0 0 0 1px #2b6cb0;
}

.position-select:hover {
}

.position-select option {
    padding: 8px;
}

/* Remove default arrow in IE */
.position-select::-ms-expand {
    display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .position-layout {
        margin: 5px 0;
        max-width: 400px;
    }
}

