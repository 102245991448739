.fen-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    padding: 0;
}

.fen-controls {
    width: 100%;
    padding: 0;
    margin: 0;
}

#edit-string {
    width: 100%;
    height: 38px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #4e5d6c;
    background-color: white;
    font-family: monospace;
    font-size: 14px;
    margin-bottom: 8px;
}

#submitFen {
    width: 100%;
}


