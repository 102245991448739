.board-display {
    display: flex;
    width: 630px;
    margin: 0 auto;
}

.ascii-board {
    width: 100%;
    padding: 10px;
    margin: 5px;
    font-size: 31px;
    background-color: lightskyblue;
    color: black;
    border-radius: 5px;
    font-family: monospace;
    white-space: pre;
    overflow-x: auto;
}


