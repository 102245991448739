.chess-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
}

.dropdowns-container {
    display: flex;
    gap: 1rem;
    padding: 0;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

/* Override the individual selector container padding */
.dropdowns-container .selector-container {
    padding: 0;
}

/* Ensure proper sizing of selects */
.dropdowns-container .select-control {
    min-width: 180px;
    margin: 0;
}

.move-controls {
    margin-bottom: 50vh;
}
/* SelectStyle.css updates */
.selector-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Change from center to flex-start */
}

.selector-label {
    color: white;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.selector-container {
    display: flex;
    justify-content: flex-start; /* Change from center to flex-start */
}

