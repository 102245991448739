body {
    margin: 0;
    float: left;
    margin-left: 50px;
    margin-bottom: 50px;
    font-family: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: lightgrayscale;
    .chess-table {
        display: flex;
        flex-direction: column;
        width: 600px;
        .fen-layout {
            margin: 5px;
            #horiz {
                #edit-string {
                    width: 485px;
                    border-radius: 5px;
                    margin: 5px;
                    height: 38px;
                }
                #submitFen {
                    height: 38px;
                    background-color: darkgreen;
                    font-size: 12px;
                    border-radius: 5px;
                    color: white;
                    margin: 5px;
                }
            }
        }
        .ascii-layout {
            margin: 5px;
            display: flex;
            #board {
                padding: 10px;
                font-size: 15px;
                background-color: lightblue;
                color: black;
                border-radius: 5px;
                margin: 5px;
                width: 100%;
            }
        }
        .moves-layout {
            margin: 5px;
            display: flex;
            justify-content: space-between;
            .moves-forward {
                #selectedMove {
                    background-color: white;
                    height: 35px;
                    border-radius: 5px;
                    margin: 5px;
                    height: 35px;
                    width: 100px;
                }
                #move {
                    width: 100px;
                    border-radius: 5px;
                    margin: 5px;
                    background-color: white;
                    height: 35px;
                    width: 100px;
                }
                #submitMove {
                    background-color: darkgreen;
                    font-size: 12px;
                    color: white;
                    margin: 5px;
                    border-radius: 5px;
                    height: 35px;
                }
            }
            #undo {
                font-size: 12px;
                background-color: darkred;
                color: white;
                margin: 5px;
                border-radius: 5px;
                height: 35px;
            }
        }
    }
}
.helper-select-container {
    position: relative;
    display: inline-block;
}

.helper-select-container select.helper-select {
    display: inline-block;
    width: auto;
    min-width: 200px;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #4e5d6c;
    border-radius: 0.375rem;
    appearance: none;
}

.helper-select-container select.helper-select:focus {
    border-color: #8e9fad;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(78, 93, 108, 0.25);
}

.helper-select-container select.helper-select option {
    color: #fff;
    background-color: #4e5d6c;
}
