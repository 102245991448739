.selector-container {
    display: flex;
    justify-content: flex-start;
}
.selector-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Change from center to flex-start */
}

.selector-label {
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.select-control {
    margin: 10px 0;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    min-width: 200px;
}

.select-control:focus {
    border-color: #4299e1;
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
}

.select-control:hover {
    background: #374151;
}

.select-control option {
    background: #2d3748;
    color: white;
    padding: 8px;
}

.select-control::-ms-expand {
    display: none;
}

.select-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    padding-right: 2.5rem;
}



