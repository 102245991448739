.theme-selector-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.theme-selector-container {
    display: flex;
    justify-content: flex-start;
}

.theme-selector .select-control {
    margin: 10px 0;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    min-width: 180px;
}
