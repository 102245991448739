.App {
    text-align: left;
}
.App .chess-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.title {
    text-align: left;
    padding-left: 20px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.display-options {
    margin: 5px;
    padding: 5px;
}

.display-options {
    margin: 10px 0;
    display: flex;
    gap: 8px;
    justify-content: center;
}

.display-toggle {
    padding: 8px 16px;
    background-color: #4a5568;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.display-toggle:hover {
    background-color: #2d3748;
}
.display-toggle:hover:not(:disabled) {
    background-color: #2d3748;
}

.display-toggle:disabled {
    background-color: #2b6cb0;
    cursor: default;
    box-shadow: 0 0 0 2px #4299e1;
}
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
}
